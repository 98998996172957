import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroFullWidth from "../components/Hero/HeroFullWidth";
import Services from "../components/Repeating/Services";
import EstatePlanningGuide from "../components/Repeating/EstatePlanningGuide";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import Logos from "../components/Repeating/Logos";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
   const heroFullWidthImages = [
      getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
      {
         ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
         media: `(max-width: 767px)`,
      },
   ];

   return (
      <Layout>
         <SearchEngineOptimization
            title="San Diego Estate Planning Law Firm | Conscious Legal"
            description="Conscious Legal is a San Diego estate planning & business advising law firm that helps families and business owners. Schedule a consultation today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroFullWidth backgroundImages={heroFullWidthImages} padding="pt-52 md:pt-60 pb-20 md:pb-104" textMaxWidth="max-w-2xl">
            <p className="font-heading text-mobile-7xl md:text-7xl font-bold gradient-text uppercase mb-6 md:mb-2">You have a legacy to leave.</p>
            <p className="font-heading italic text-mobile-3xl md:text-3xl text-white font-medium mb-7 md:mb-10">
               We’ll help you protect it for your family and business.
            </p>
            <ButtonSolid as="button" modal="modal-contact" text="Get a Free Consultation" />
         </HeroFullWidth>

         <section className="bg-primary-50">
            <div className="container">
               <div className="relative pt-14 md:pt-22 pb-18 px-4 md:px-20 lg:px-44 bg-white md:shadow-3xl md:rounded-xl md:md:-mt-72 -mx-4 md:mx-0 mb-20 md:mb-28">
                  <header className="text-center">
                     <h1 className="text-mobile-5xl md:text-5xl normal-case text-blue-900">
                        Your Conscious Estate Planning Law Firm Serving San Diego & Statewide
                     </h1>
                     <p className="md:text-lg">
                        At Conscious Legal, APC, we help California families and business owners pass on more than just money. Our San Diego estate
                        planning law firm will ensure your wishes are honored, and that the individuals you designate will make your health and
                        personal care decisions if you are incapacitated. Direct your legal life planning. Protect your money and treasured assets for
                        generations.
                     </p>
                     <p className="font-display text-mobile-5xl md:text-5xl text-primary-600 mb-0">Leave a true legacy of love.</p>
                  </header>
               </div>
            </div>

            <Services className="pb-16 md:pb-24" headingLevel="h2" cardHeadingLevel="h3" />
         </section>

         <WhyUs className="pt-24 md:pt-16 mb-20 md:mb-36" headingLevel="h2" />

         <EstatePlanningGuide className="pb-16 md:pb-28" headingLevel="h2" />

         <Testimonials headingLevel="h2" />

         <About headingLevel="h2" />

         <CallToAction headingLevel="h2" />

         <Logos className="pt-4 md:pt-10 pb-20 md:pb-28" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Homepage_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Homepage_TW.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "home/1.0 Hero Desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      heroMobile: file(relativePath: { eq: "home/1.0 Hero Mobile.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
